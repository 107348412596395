import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "./HomePage.css";

function HomePage() {
  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [festivals, setDanceStyles] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/festivals/?limit=5`)
      .then((response) => response.json())
      .then((data) => {
        setDanceStyles(data);
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, []);

  return (
    <div className="home-page">
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              height: "600px",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              className="home-page-starter"
              sx={{
                color: "white",
                textShadow: "1px 1px 3px black",
              }}
            >
              Never Forget Your Next Move
            </Typography>
            <Typography
              variant="h5"
              paragraph
              sx={{
                color: "white",
                textShadow: "1px 1px 3px black",
                mb: 5,
              }}
            >
              From mastering intricate figures to finding your next
              unforgettable festival, DanceMaps is your ultimate dance
              companion. <br /> Learn, practice, and connect with the global social
              dance scene – all in one place.
            </Typography>
            <Button
              component={Link}
              to="/dance_dictionary"
              variant="contained"
              color="primary"
              size="large"
              sx={{
                fontSize: "1.2rem",
                padding: "16px 32px",
              }}
            >
              Get Started
            </Button>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                display: "flex", // Use Flexbox
                flexDirection: "column", // Stack children vertically
                alignItems: "center", // Center children horizontally
                justifyContent: "center", // Center children vertically
                width: "100%", // Take up full width
              }}
            >
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  color: "white",
                  textShadow: "1px 1px 3px black",
                }}
              >
                Upcoming Social Festivals
              </Typography>
            </Box>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              renderDotsOutside={false}
              infinite={true}
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={5000}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              className="custom-carousel"
            >
              {festivals.map((festival) => (
                <Link
                  to="/festival"
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <Box
                    key={festival.id}
                    sx={{
                      display: "flex", // Use Flexbox
                      flexDirection: "column", // Stack children vertically
                      alignItems: "center", // Center children horizontally
                      justifyContent: "center", // Center children vertically
                      width: "100%", // Take up full width
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative", // Make the Box a positioning context
                        minWidth: "250px",
                        width: "70%",
                        height: "350px",
                        overflow: "hidden", // Hide any overflow from the images
                        borderRadius: "10px",
                        border: "5px solid #fff",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 1)",
                      }}
                    >
                      {/* Background Image (contain) */}
                      <img
                        src={
                          festival.img !== "nan"
                            ? festival.img
                            : `${s3BucketUrl}/dancestyle_images/festivals_alt.png`
                        }
                        alt={festival.name}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "cover", // Scale to fit within container, may have letterboxing
                          filter: "blur(3px)", // Blur this image
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${s3BucketUrl}/dancestyle_images/festivals_alt.png`;
                        }}
                      />

                      {/* Foreground Image (cover) */}
                      <img
                        src={
                          festival.img !== "nan"
                            ? festival.img
                            : `${s3BucketUrl}/dancestyle_images/festivals_alt.png`
                        }
                        alt={festival.name}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          objectFit: "contain", // Cover the entire container, may crop
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = `${s3BucketUrl}/dancestyle_images/festivals_alt.png`;
                        }}
                      />
                    </Box>
                  </Box>
                </Link>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HomePage;
