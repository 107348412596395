import { Container, Divider, Grid, Typography } from "@mui/material";
import * as React from "react";
import { useStore } from "../store/store.js";
import FigureBox from "./figureBox/FigureBox";

function Body() {
  const danceStore = useStore();

  const content =
    danceStore.currentFigure.length > 0 ? (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              sx={{ color: "black", backgroundColor: "white", my: 2 }}
            >
              Current figure
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ mb: 2 }} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              className="home-page-title"
              sx={{
                color: "black",
                backgroundColor: "white",
                my: 2,
              }}
            >
              Next possible figures
            </Typography>
            <Divider orientation="vertical" flexItem sx={{ mb: 2 }} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {/* first Grid item */}
          <Grid item xs={6}>
            {danceStore.currentFigure.map((figure) => (
              <FigureBox
                key={figure.id}
                figureData={figure}
                isCurrentFigure={true}
              />
            ))}
          </Grid>
          {/* second Grid item */}
          <Grid item xs={6}>
            <Grid
              container
              direction="row" // Arrange items vertically
              spacing={2}
              sx={{ height: "75vh", overflowY: "auto" }}
            >
              {danceStore.nextFigures.map((figure) => (
                <Grid item xs={12} key={figure.id}>
                  <FigureBox figureData={figure} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </>
    ) : (
      <Typography
        variant="h5"
        sx={{ color: "black", backgroundColor: "white", my: 2 }}
      >
        Choose a dance style
      </Typography>
    );

  return (
    <Container maxWidth="md" sx={{ height: "84vh" }}>
      {content}
    </Container>
  );
}

export default Body;
