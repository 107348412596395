import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import * as React from "react";
import { useState } from "react";
import "./FestivalsFilter.css";

function FestivalFilters({ filters, onFilterChange, filterTypes }) {
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);

  const handleFavoriteToggle = () => {
    setShowFavoritesOnly(!showFavoritesOnly);
    // Notify the parent component about the change
    onFilterChange({
      target: { name: "showFavoritesOnly", value: !showFavoritesOnly },
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5">Filter By:</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Search"
          variant="outlined"
          name="location"
          value={filters.location}
          onChange={onFilterChange}
          sx={{ width: "90%" }}
        />
      </Grid>

      <Grid item xs={12}>
        {/* Other Filters (Select Example) */}
        <FormGroup>
          <Typography variant="h6">Festival Type:</Typography>
          {filterTypes.map((type) => (
            <FormControlLabel
              onChange={onFilterChange}
              control={<Checkbox name={type} />}
              label={type}
            />
          ))}
        </FormGroup>
      </Grid>

      <Grid item xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Filter by date"
            inputFormat="dd.mm.yyyy"
            value={dayjs(filters.startDate)}
            onChange={(date) =>
              onFilterChange({ target: { name: "startDate", value: date } })
            }
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={showFavoritesOnly}
              onChange={handleFavoriteToggle}
              name="showFavoritesOnly"
              color="primary"
            />
          }
          label="Show Favorites Only"
        />
      </Grid>
    </Grid>
  );
}

export default FestivalFilters;
