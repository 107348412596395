import React from 'react';
import './Soon.css';

function Soon() {



  return (
    <div className="soon">
      <h1>Coming Soon</h1>
    </div>
  );
}

export default Soon;