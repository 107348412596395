import { Box, Chip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import React from "react";
import { Calendar, GeoAlt } from "react-bootstrap-icons";
import FestivalFavoriteButton from "./FestivalFavoriteButton";
import backgroundTexture from "./backgroundtexture.jpg";

function FestivalCard({ festival }) {
  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;
  return (
    <Card
      sx={{
        maxWidth: 345,
        minHeight: 360,
        position: "relative",
        marginX: "auto",
        backgroundImage: `url(${backgroundTexture})`,
        backgroundPosition: "center",
      }}
    >
      <a href={`${festival.website}`} target="_blank" rel="noopener noreferrer">
        <Box
          sx={{
            position: "relative",
            minWidth: "250px",
            height: "200px",
            overflow: "hidden",
          }}
        >
          <img
            src={
              festival.img !== "nan"
                ? festival.img
                : `${s3BucketUrl}/dancestyle_images/festivals_alt.png`
            }
            alt={festival.name}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              filter: "blur(3px)",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${s3BucketUrl}/dancestyle_images/festivals_alt.png`;
            }}
          />

          {/* Foreground Image (cover) */}
          <img
            src={
              festival.img !== "nan"
                ? festival.img
                : `${s3BucketUrl}/dancestyle_images/festivals_alt.png`
            }
            alt={festival.name}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "contain", // Cover the entire container, may crop
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${s3BucketUrl}/dancestyle_images/festivals_alt.png`;
            }}
          />
        </Box>
      </a>
      <Box sx={{ position: "absolute", top: 8, right: 8 }}>
        <FestivalFavoriteButton festival={festival} />
      </Box>
      <CardContent
        sx={{
          minHeight: 100,
        }}
      >
        <Typography
          gutterBottom
          variant="h6"
          component="div"
          sx={{
            fontSize: { sm: "2vw", md: "1.4vw" },
            overflow: "hidden",
            textOverflow: "ellipsis",
            minHeight: 30,
          }}
        >
          {festival.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <GeoAlt color="blue" />
          <Typography variant="body2" color="text.secondary">
            {festival.location}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Calendar color="blue" />
          <Typography variant="body2" color="text.secondary">
            {festival.time}
          </Typography>
        </Box>
      </CardContent>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: 30,
        }}
      >
        <Typography variant="body2" color="text.secondary" component="div">
          Promo Code:
        </Typography>
        {festival.promocode !== "nan" ? (
          <>
            <Chip
              label={`${festival.promocode}`}
              color="primary"
              sx={{ mt: 0.5 }}
            />
          </>
        ) : (
          <Typography
            sx={{
              color: "red",
              fontSize: "12px",
            }}
          >
            Coming Soon
          </Typography>
        )}
      </Box>
    </Card>
  );
}

export default FestivalCard;
