import { Facebook, Instagram } from "@mui/icons-material";
import {
  Box,
  Container,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <Box className={styles.footer} component="footer">
      <Container maxWidth="md">
        <Box className={styles["footer-content"]}>
          <Typography variant="body2" className={styles.copyright}>
            &copy; {new Date().getFullYear()} DanceMaps. All rights reserved.
          </Typography>
          <Stack direction="row" className={styles["legal-links"]}>
            <Link href="/privacy-policy" color="inherit" underline="hover">
              Privacy Policy
            </Link>
            <Link href="/terms-of-service" color="inherit" underline="hover">
              Terms of Service
            </Link>
            <Link href="/disclaimer" color="inherit" underline="hover">
              Disclaimer
            </Link>
          </Stack>
          <Stack direction="row" spacing={1} className={styles["social-media"]}>
            <IconButton
              color="inherit"
              aria-label="Facebook"
              href="https://www.facebook.com/profile.php?id=61560910366260"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="Instagram"
              href="https://instagram.com/dancemaps1/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </IconButton>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
