import React, { useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useStore } from "../store/store.js";
import "./Body.css";
import BodySmallHorSwiper from "./BodySmallHorSwiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/manipulation";
import "swiper/css/pagination";

function BodySmall() {
  const danceStore = useStore();

  const [verticalSwiperRef, setVerticalSwiperRef] = useState(null);
  const [shouldUpdateFiguresList, setShouldUpdateFiguresList] = useState(true);
  const [figuresList, setFiguresList] = useState([]);

  function getActiveFigureName() {
    const activeIndex = verticalSwiperRef?.activeIndex;
    const figure_name = figuresList[activeIndex]?.figures[0].name;
    return figure_name;
  }

  useEffect(() => {
    if (danceStore.currentFigure.length > 0 && shouldUpdateFiguresList) {
      setFiguresList([
        { id: 0, figures: danceStore.currentFigure },
        { id: 1, figures: danceStore.nextFigures },
      ]);
      setShouldUpdateFiguresList(false);
    }
  }, [danceStore.currentFigure]);

  useEffect(() => {
    if (verticalSwiperRef && !verticalSwiperRef.destroyed) {
      const activeIndex = verticalSwiperRef?.activeIndex;
      setFiguresList((oldNextFiguresList) => {
        const newId = activeIndex + 1;
        const existingIndex = oldNextFiguresList.findIndex(
          (item) => item.id === newId
        );

        if (existingIndex !== -1) {
          // If an object with the same ID exists, replace it
          const updatedList = [...oldNextFiguresList];
          updatedList[existingIndex] = {
            id: newId,
            figures: danceStore.nextFigures,
          };
          return updatedList.slice(0, existingIndex + 1);
        } else {
          // If no object with the same ID exists, append a new one
          return [
            ...oldNextFiguresList,
            { id: newId, figures: danceStore.nextFigures },
          ];
        }
      });
    }
  }, [danceStore.nextFigures]);

  async function addSlide() {
    const figure_name = getActiveFigureName();
    danceStore.fetchNextFigure(figure_name);
  }

  const swiper = useRef();

  function handleStepChange(e) {
    // run if the last slide is active
    if (e.realIndex === figuresList.length - 1) {
      addSlide();
    }
  }
  const verticalSettings = {
    onSwiper: setVerticalSwiperRef,
    slidesPerView: 1.2,
    ref: swiper,
    direction: "vertical",
    pagination: {
      clickable: true,
      dynamicBullets: true,
    },
    modules: [Pagination, Navigation],
    onSlideChange: handleStepChange,
    className: "vertical-swiper",
  };

  if (danceStore.currentFigure.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Swiper {...verticalSettings} style={{ height: "80vh" }}>
        {figuresList.map((item, index) => {
          return (
            <SwiperSlide key={`${item.id}-${index}`} className="swiperSlide">
              <BodySmallHorSwiper
                figuresItem={item.figures}
                figuresList={figuresList}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default BodySmall;
