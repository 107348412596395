import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal } from "@mui/material";
import Grid from "@mui/material/Grid";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import FestivalCard from "./FestivalCard.js";
import markerIconPng from "./marker-icon.png";

function FestivalMap({ filteredFestivals, showMap, handleToggleMap }) {
  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
  });
  return (
    <Modal
      open={showMap}
      onClose={handleToggleMap}
      aria-labelledby="festival-map-modal"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 0,
        }}
      >
        {/* Close button */}
        <IconButton
          aria-label="close"
          onClick={handleToggleMap}
          sx={{ position: "absolute", top: 3, right: 3 }}
        >
          <CloseIcon />
        </IconButton>

        {/* Map */}
        <MapContainer
          center={[51.505, -0.09]}
          zoom={2}
          style={{ height: "90vh", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            noWrap={true}
          />
          {filteredFestivals.map(
            (festival) =>
              festival.latitude &&
              festival.longitude && (
                <Marker
                  key={festival.id}
                  position={[festival.latitude, festival.longitude]}
                  icon={customIcon}
                >
                  <Popup>
                    <Grid item xs={12} key={festival.id}>
                      <FestivalCard festival={festival} />
                    </Grid>
                  </Popup>
                </Marker>
              )
          )}
        </MapContainer>
      </Box>
    </Modal>
  );
}

export default FestivalMap;
