import { Facebook, Instagram } from "@mui/icons-material"; // Import social media icons
import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/contact/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData), // Send form data (name, email, message)
        }
      );

      if (response.ok) {
        // console.log("Message sent successfully");
        // Optionally, clear the form and show a success message
        setFormData({ name: "", email: "", message: "" });
      } else {
        console.error("Error sending message:", response.statusText);
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle network errors or other exceptions
    }
  };

  return (
    <Box sx={{ padding: 4, maxWidth: 600, margin: "auto" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1">
            We'd love to hear from you. Feel free to reach out through the form
            below or connect with us on social media:
          </Typography>
        </Grid>

        {/* Social Media Links */}
        <Grid item xs={12} container justifyContent="center">
          <Link
            href="https://instagram.com/dancemaps1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton aria-label="Instagram">
              <Instagram style={{ color: "#E1306C" }} fontSize="large" />
            </IconButton>
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61560910366260"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton color="primary" aria-label="Facebook">
              <Facebook fontSize="large" />
            </IconButton>
          </Link>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              name="message"
              value={formData.message}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              Send Message
            </Button>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
