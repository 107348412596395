import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useStore } from "../../store/store.js";

import "./Figure.css";

function FigureBox({
  figureData,
  showTeacher = false,
  isCurrentFigure = false,
}) {
  const { id, name, description, unique_id } = figureData;
  const s3BucketUrl = process.env.REACT_APP_AWS_S3_BUCKET_URL;

  const danceStore = useStore();
  const selectedStyle = danceStore.style;

  const handleFigureClick = (figure) => {
    // make starterFigure empty
    danceStore.setStarterFigure("");
    // set selected figure as currentFigure
    danceStore.setCurrentFigure(selectedStyle, figure);
    // set nextFigures
    danceStore.fetchNextFigure(figure);
  };

  let difficultyClass = "";
  if (unique_id.endsWith("BEG")) {
    difficultyClass = "beg";
  } else if (unique_id.endsWith("INT")) {
    difficultyClass = "int";
  } else if (unique_id.endsWith("ADV")) {
    difficultyClass = "adv";
  }

  const adjustName = (name) => {
    let adjustedName = name
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letters
    return adjustedName;
  };
  const displayText = description ? description : adjustName(name);

  return (
    <Tooltip title={unique_id} enterDelay={1000} leaveDelay={100}>
      <div
        className={`figure-box ${difficultyClass} ${
          isCurrentFigure ? "no-hover" : ""
        }`}
        id={name}
      >
        {/* <div className="figure-image" onClick={() => handleFigureClick(name)}>
        <img
          src={`${s3BucketUrl}/figure_images/${unique_id}.gif`}
          alt={name.replace(/_/g, "\n")}
        />
      </div> */}
        <div className="figure-image" onClick={() => handleFigureClick(name)}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: "100%", height: "100%" }} // Adjust as needed
          >
            <source
              src={`${s3BucketUrl}/figure_videos/${unique_id}.mp4`}
              type="video/mp4"
            />
            {/* Add more <source> tags if you want to support multiple video formats */}
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="figure-details">
          {showTeacher && (
            <>
              <hr></hr>
              <h6 className="teacher">Teacher </h6>
            </>
          )}
          {displayText ? <p>{displayText}</p> : null}
        </div>
      </div>
    </Tooltip>
  );
}

export default FigureBox;
