import React from "react";
import "./About.css";

const About = () => {
  return (
    <div className="about-page">
      <h1>About DanceMaps</h1>

      <section className="about-section">
        <h2>Your Ultimate Social Dance Companion</h2>
        <p>
          DanceMaps is your comprehensive resource for mastering social dance
          figures and discovering exciting dance festivals around the world.
          Whether you're a beginner or an experienced dancer, our platform
          offers a unique and engaging way to learn, practice, and perfect your
          favorite moves.
        </p>
      </section>

      <section className="about-section">
        <div className="about-section">
          <h2>Features</h2>
          <table className="about-table">
            <tbody>
              <tr>
                <td className="about-td">
                  <strong>Interactive Figure Library:</strong>
                </td>
                <td className="about-td">
                  Dive into an extensive library of dance figures with detailed
                  instructions, visual demonstrations, and tips for each move.
                </td>
              </tr>
              {/* <tr>
                <td className="about-td">
                  <strong>Personalized Practice Plans:</strong>
                </td>
                <td className="about-td">
                  Create custom practice routines tailored to your skill level and
                  goals, helping you remember and refine your favorite figures.
                </td>
              </tr> */}
              <tr>
                <td className="about-td">
                  <strong>Global Festival Directory:</strong>
                </td>
                <td className="about-td">
                  Discover upcoming festivals, congresses, and events across the
                  globe, featuring the latest trends and top artists in social
                  dance.
                </td>
              </tr>
              {/* <tr>
                <td className="about-td">
                  <strong>Community & Connection:</strong>
                </td>
                <td className="about-td">
                  Connect with a vibrant community of dancers, share your
                  experiences, and find partners to practice with.
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </section>

      <section className="about-section">
        <h2>Join the DanceMaps Community</h2>
        <p>
          Embark on your dance journey with DanceMaps. Learn new figures,
          connect with fellow dancers, and never miss another exciting festival.
          Let's dance together!
        </p>
      </section>
    </div>
  );
};

export default About;
