import {
  Button,
  Collapse,
  Container,
  IconButton,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";

import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { GlobeAmericas, Sliders } from "react-bootstrap-icons";
import "react-datepicker/dist/react-datepicker.css";
import { useFavoritesStore } from "../../store/favoritesStore";
import { useStore } from "../../store/store.js";
import FestivalCard from "./FestivalCard.js";
import FestivalMap from "./FestivalMap"; // Import the Map component
import "./Festivals.css";
import FestivalFilters from "./festivals-filter/FestivalsFilter.js";

function Festivals() {
  const [festivals, setFestivals] = useState([]);
  const [filters, setFilters] = useState({
    location: "",
    startDate: new Date(),
    type: "",
  });
  const [filterTypes, setFilterTypes] = useState([]);
  const danceStore = useStore();

  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showFilters, setShowFilters] = useState(isSmScreen); // Collapsed by default on larger screens
  const toggleFilters = () => setShowFilters(!showFilters);
  const [showMap, setShowMap] = useState(false);
  const handleToggleMap = () => {
    setShowMap(!showMap);
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/festivals/`)
      .then((response) => response.json())
      .then((data) => {
        setFestivals(data);
        // Create a unique list of all types
        const types = [
          ...new Set(
            data.flatMap((festival) =>
              festival.type.split(", ").map((type) => type.trim())
            )
          ),
        ];
        setFilterTypes(types);
        // Initialize filters.type with the unique types, all set to false
        setFilters((prevFilters) => ({
          ...prevFilters,
          type: types.reduce((acc, type) => ({ ...acc, [type]: false }), {}),
        }));
      })
      .catch((error) => console.error("Error fetching data: ", error));
    danceStore.reset();
  }, []);

  useEffect(() => {
    setShowFilters(!isSmScreen);
  }, [isSmScreen]);

  const favorites = useFavoritesStore((state) => state.favorites);

  const filteredFestivals = festivals.filter((festival) => {
    const locationMatch =
      !filters.location ||
      festival.location
        .toLowerCase()
        .includes(filters.location.toLowerCase()) ||
      festival.name.toLowerCase().includes(filters.location.toLowerCase());

    const selectedTypes = Object.keys(filters.type).filter(
      (type) => filters.type[type] === true
    );

    const typeMatch =
      selectedTypes.length === 0 ||
      selectedTypes.some((type) => festival.type.includes(type));

    const isFavorite = favorites.includes(festival.id);
    // Apply the favorites filter only if showFavoritesOnly is true
    if (filters.showFavoritesOnly) {
      return isFavorite;
    }

    if (filters.startDate) {
      const filterDate = new Date(filters.startDate).setHours(0, 0, 0, 0); // Start of day in user's timezone
      const festivalDate = new Date(festival.start).setHours(0, 0, 0, 0); // Start of day in festival's timezone

      return festivalDate >= filterDate && locationMatch && typeMatch;
    }

    return locationMatch && typeMatch;
  });

  const onFilterChange = (e) => {
    if (filterTypes.includes(e.target.name)) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        type: {
          ...prevFilters.type,
          [e.target.name]: e.target.checked,
        },
      }));
    } else {
      setFilters({ ...filters, [e.target.name]: e.target.value });
    }
  };

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          height: "60px",
          backgroundImage:
            "url(https://dancemap-bucket.s3.eu-central-1.amazonaws.com/dancestyle_images/home-page-wallpaper-aachener-weiher-mirrored.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "0px 0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h5"
          className="home-page-title"
          sx={{
            color: "white",
            marginLeft: 2,
            textShadow: "1px 1px 3px black",
          }}
        >
          Festival Calendar
        </Typography>
      </Container>
      <Container sx={{ backgroundColor: "#faf8ff", pt: "24px" }}>
        <Grid container sx={{ width: "100%" }}>
          <Grid container spacing={2}>
            {/* first Grid item */}
            <Grid item xs={12} sm={4} md={4} lg={3} sx={{ mt: 2 }}>
              {isSmScreen && (
                <Box sx={{ display: "flex", justifyContent: "center", gap: 5 }}>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={toggleFilters}
                    style={{
                      cursor: "pointer",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton color="primary" aria-label="open filters">
                      <Sliders />
                    </IconButton>
                    Filters
                  </Link>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleToggleMap}
                    sx={{ mb: 2, mt: 2 }}
                  >
                    <GlobeAmericas style={{ marginRight: "8px" }} />
                    {showMap ? "Hide Map" : "Show Map"}
                  </Button>
                </Box>
              )}
              <Collapse in={showFilters}>
                <FestivalFilters
                  filters={filters}
                  onFilterChange={onFilterChange}
                  filterTypes={filterTypes}
                />
              </Collapse>
              {!isSmScreen && (

              <Button
                variant="contained"
                color="primary"
                onClick={handleToggleMap}
                sx={{ mb: 2, mt: 2 }}
              >
                <GlobeAmericas style={{ marginRight: "8px" }} />
                {showMap ? "Hide Map" : "Show Map"}
              </Button>
              )}
            </Grid>

            {/* Map */}
            <FestivalMap
              filteredFestivals={filteredFestivals}
              showMap={showMap}
              handleToggleMap={handleToggleMap}
            />

            {/* Festival card, second Grid item */}
            <Grid item xs={12} sm={8} md={8} lg={9}>
              <Grid
                container
                spacing={2}
                className="centered-festival-list"
                sx={{ height: "75vh", overflowY: "auto" }}
              >
                {filteredFestivals.map((festival) => (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={festival.id}>
                    <FestivalCard festival={festival} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Festivals;
