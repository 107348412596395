import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useStore = create(
  persist(
    (set, get) => ({
      style: "",
      starterFigures: [],
      nextFigures: [],
      currentFigure: [],
      loading: false,
      searchQuery: "",
      searchResults: [],
      setStyle: (style) => set({ style }),
      setStarterFigure: async (style) => {
        set({ loading: true });
        if (style === "") {
          set({ starterFigures: [], loading: false });
        } else {
          // fetch data from API
          try {
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}`
            );
            if (res.ok) {
              const data = await res.json();
              const starterFigures = data.filter(
                (figure) => figure.starter === true
              );
              const initialNextFigures =
                starterFigures.length > 0 ? starterFigures[0].next_figure : [];

              // wait for half a second
              set({
                starterFigures,
                currentFigure: [starterFigures[0]],
                loading: false,
                nextFigures: initialNextFigures,
              });
            }
          } catch (error) {
            console.error("Error:", error);
            set({ loading: false });
          }
        }
      },
      fetchNextFigure: async (figure) => {
        const style = get().style;
        set({ loading: true });
        if (figure.length === 0) {
          set({ nextFigures: [], loading: false });
        } else {
          // fetch data from API
          try {
            const res = await fetch(
              `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}&selected_figure=${figure}`
            );
            if (res.ok) {
              const data = await res.json();
              const nextFigures = data[0].next_figure;
              // wait for half a second
              set({ nextFigures, loading: false });
            }
          } catch (error) {
            console.error("Error:", error);
            set({ loading: false });
          }
        }
      },
      setCurrentFigure: async (style, figure) => {
        set({ loading: true });
        // fetch data from API
        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}&selected_figure=${figure}`
          );
          if (res.ok) {
            const data = await res.json();
            // wait for half a second
            set({ currentFigure: data, loading: false });
          }
        } catch (error) {
          console.error("Error:", error);
          set({ loading: false });
        }
      },
      searchFigures: async (style, query) => {
        set({ loading: true, searchQuery: query }); // Update searchQuery and set loading to true

        try {
          const res = await fetch(
            `${process.env.REACT_APP_API_URL}/figures/?selected_style=${style}&search=${query}`
          );

          if (res.ok) {
            const data = await res.json();
            set({ searchResults: data, loading: false });
          } else {
            console.error("Error searching for figures:", res.statusText);
            set({ searchResults: [], loading: false }); // Clear results on error
          }
        } catch (error) {
          console.error("Error:", error);
          set({ searchResults: [], loading: false });
        }
      },
      reset: () =>
        set({
          style: "",
          starterFigures: [],
          nextFigures: [],
          currentFigure: [],
          loading: false,
        }),
    }),
    {
      name: "dance-store",
      whitelist: ["style"],
    }
  )
);
