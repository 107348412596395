import {
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

function TermsOfService() {
  const currentYear = new Date().getFullYear();

  return (
    <Container maxWidth="md" sx={{ padding: "2rem" }}>
      <Typography variant="h4" gutterBottom>
        DanceMaps Terms of Service
      </Typography>

      <Typography variant="body1" paragraph>
        Welcome to DanceMaps! These Terms of Service ("Terms") govern your
        access to and use of our website and services, including information
        about dance figures and events. By accessing or using DanceMaps, you
        agree to be bound by these Terms. If you do not agree to these Terms,
        please do not use our website.
      </Typography>

      <Typography variant="h5" gutterBottom>
        1. Acceptance of Terms:
      </Typography>
      <Typography variant="body1" paragraph>
        By using DanceMaps, you confirm that you are at least 18 years old and
        have the legal capacity to enter into these Terms. If you are under 18,
        you may use DanceMaps only with the involvement of a parent or guardian.
      </Typography>

      <Typography variant="h5" gutterBottom>
        2. Use of DanceMaps:
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="a. Content:"
            secondary="You may use DanceMaps to access information about dance figures, tutorials, events, and classes. You may not use DanceMaps for any illegal or unauthorized purpose."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="b. Account Creation:"
            secondary="If you create an account, you are responsible for maintaining the confidentiality of your account information and password. You agree to accept responsibility for all activities that occur under your account."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="c. Prohibited Activities:"
            secondary="You agree not to use DanceMaps to harass, abuse, threaten, or defame any person or entity. You also agree not to upload or share any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, hateful, or racially, ethnically, or otherwise objectionable."
          />
        </ListItem>
        {/* You might want to add a clause here about not using DanceMaps to infringe on copyrights or other intellectual property rights related to dance figures/choreography. */}
      </List>

      <Typography variant="h5" gutterBottom>
        3. Intellectual Property:
      </Typography>
      <Typography variant="body1" paragraph>
        All content on DanceMaps, including but not limited to text, graphics,
        logos, images, videos, and software, is the property of DanceMaps or its
        content suppliers and is protected by international copyright laws. You
        may not modify, copy, distribute, transmit, display, perform, reproduce,
        publish, license, create derivative works from, transfer, or sell any
        information, software, products, or services obtained from DanceMaps
        without our express written consent.
      </Typography>

      {/* ... (other sections: Limitation of Liability, Indemnification, Termination, etc.) ... */}
      {/* ... (Changes to Terms, Contact Us) ... */}

      <Typography variant="caption" display="block" gutterBottom>
        Last updated: {currentYear}
      </Typography>
    </Container>
  );
}

export default TermsOfService;
